<i18n lang="yaml">
pt:
  data: 'Dados'
  exportCategoryAsAIContext: 'Exportar categoria como contexto para usar em IA'
  home: 'Loja de Apps'
  settings: 'Ajustes'
  views: 'Páginas'
  workflows: 'Automações'
en:
  data: 'Data'
  exportCategoryAsAIContext: 'Export category as context to use in AI'
  home: 'App Store'
  settings: 'Settings'
  views: 'Pages'
  workflows: 'Automations'
</i18n>

<template>
  <deck-button-group
    v-if="$auth.loggedIn && $auth.user.role === 'admin'"
    class="studio-topbar__pages-menu"
    :model-value="currentValue"
    :buttons="builderItems"
    buttons-kind="ghost"
    color="controls"
    kind="radio"
    mandatory
  />
</template>

<script lang="ts">
import { mapActions, mapGetters } from '~/assets/javascript/modules/vuex';
import { streamDownload } from '~/assets/javascript/utils';

export default defineComponent({
  name: 'StudioTopbarPagesMenu',
  setup() {
    return {
      t: useI18n().t,
      ...mapGetters('workspace', ['tenantSlug', 'currentCategory', 'viewsOrder', 'workflowsOrder']),
      ...mapGetters('features', ['isFeatureEnabled']),
      ...mapActions('features', ['fetchFeatures']),
    };
  },
  data() {
    return {
      exporting: false,
    };
  },
  computed: {
    builderItems() {
      const items = [
        { value: 't-tenantSlug-studio-data', icon: 'database', text: this.t('data') },
        { value: 't-tenantSlug-studio-pages', icon: 'browser', text: this.t('views') },
        { value: 't-tenantSlug-studio-automations', icon: 'bolt', text: this.t('workflows') },
        { value: 't-tenantSlug-studio-settings', icon: 'gear', text: this.t('settings') },
      ].map(item => ({
        ...item,
        to: this.localePath({ name: item.value, params: { tenantSlug: this.tenantSlug } }),
      }));

      if (this.showExportCategoryAsYaml) {
        items.push({
          value: 'exportCategoryAsAIContext',
          icon: 'download',
          text: this.t('exportCategoryAsAIContext'),
          loading: this.exporting,
          onClick: this.exportCategoryAsAIContext,
        });
      }

      return items.map(item => ({
        ...item,
        tooltipProps: {
          position: 'bottom',
          instant: true,
        },
      }));
    },
    currentValue() {
      return this.builderItems.find(item => this.$route.name.includes(item.value))?.value;
    },
    showExportCategoryAsYaml() {
      return this.isFeatureEnabled('exportCategoryAsAIContext') && ['t-tenantSlug-studio-automations', 't-tenantSlug-studio-pages'].includes(this.currentValue);
    },
  },
  async mounted() {
    await this.fetchFeatures();
  },
  methods: {
    async exportCategoryAsAIContext() {
      if (this.exporting) return;

      try {
        this.exporting = true;

        const { data } = await this.$api.$post('/builder/apps', {
          body: {
            app: this.currentCategory.category_name,
            icon: this.currentCategory.category_icon,
            category: this.currentCategory.category_system_name || 'home',
            solutions: ['peopleops'],
            categories: ['company'],
            description: this.currentCategory.category_name,
            views: this.extractIds(this.viewsOrder),
            workflows: this.extractIds(this.workflowsOrder),
            template_format: 'ai',
          },
        });

        streamDownload(this.currentCategory.category_name, 'json', data);
      } catch (error) {
        this.$errorRescue(this, error, 'exportingCategoryAsAIContext');
      } finally {
        this.exporting = false;
      }
    },
    extractIds(resourcesOrder) {
      return resourcesOrder.map((item) => {
        if (item.id) return item.id;
        return this.extractIds(item.items);
      }).flat();
    },
  },
});
</script>

<style lang="scss">
.studio-topbar__pages-menu .v-slide-group__content {
    flex-wrap: wrap;
    justify-content: center;
  }
</style>
